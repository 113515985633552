<template>
  <mi-dialog
    :close-icon="false"
    :model-value="showModal"
    full-width
    full-height
    no-esc-dismiss
    no-backdrop-dismiss
    seamless
    class="schedules-search"
  >
    <mi-form class="schedules-search__form" @submit="onHandleSubmit">
      <schedules-search-modal-header
        :is-edit="isEdit"
        :close-modal="onHandleModalCloseClick"
        :is-create-collection-loading="isCreateCollectionLoading"
        :allow-submit="allowSubmit"
      ></schedules-search-modal-header>
      <schedules-search-modal-body></schedules-search-modal-body>
    </mi-form>
  </mi-dialog>
</template>

<script>
  import { ref, provide, onMounted, watch } from 'vue'
  import { useRouter } from 'vue-router'
  import { useRouteParams } from '@/composables/useRouteParams'
  import { SCHEDULES_DETAILS_ROUTE } from '@/router/routeNames'
  import SchedulesSearchModalHeader from '@/components/schedules/search/modal/SchedulesSearchModalHeader.vue'
  import SchedulesSearchModalBody from '@/components/schedules/search/modal/SchedulesSearchModalBody.vue'
  import { useElements, useRecentSearch } from '@/components/schedules/utils'
  import notify from '@/utils/notify'
  import * as notificationTypes from '@/plugins/quasar/notifications/notificationTypes'
  import {
    createCollection,
    showHTTPErrorNotification,
    getScheduleCollections,
    saveEditedCollection
  } from '@/api'
  import {
    COLLECTION_NAME_PROVIDER,
    USE_ELEMENTS_PROVIDER,
    USE_RECENT_SEARCH_PROVIDER,
    SEARCH_RESULT_TYPES
  } from '@/constants'
  import { getElementTypes } from '@/utils/filterListByString'

  export default {
    name: 'SchedulesSearchModal',
    components: { SchedulesSearchModalBody, SchedulesSearchModalHeader },
    setup() {
      const router = useRouter()
      const { routeParams } = useRouteParams()
      const useElementsProvider = useElements()
      const useRecentSearchProvider = useRecentSearch()

      const showModal = ref(true)
      const isCreateCollectionLoading = ref(false)
      const isEdit = ref(Boolean(routeParams.value.edit))
      const collectionName = ref('')
      const allowSubmit = ref(false)

      provide(USE_ELEMENTS_PROVIDER, useElementsProvider)
      provide(COLLECTION_NAME_PROVIDER, collectionName)
      provide(USE_RECENT_SEARCH_PROVIDER, useRecentSearchProvider)

      const onHandleModalCloseClick = () => {
        router.push({ name: SCHEDULES_DETAILS_ROUTE.name, params: { id: routeParams.value.id } })
      }

      const onHandleSubmit = async () => {
        isCreateCollectionLoading.value = true

        const params = {
          scheduleId: routeParams.value.id,
          collectionName: collectionName.value,
          options: useElementsProvider.selectedElements.options,
          components: useElementsProvider.selectedComponentsWithLEs()
        }

        try {
          if (isEdit.value) {
            await saveEditedCollection(
              {
                scheduleId: routeParams.value.id,
                collectionId: routeParams.value.collectionId,
                ...params
              }
            )

            notify({
              content: 'Collection Updated.',
              type: notificationTypes.BASIC_INFO,
              position: 'bottom'
            })
          }
          else {
            await createCollection(params)

            notify({
              content: 'Collection created.',
              type: notificationTypes.BASIC_INFO,
              position: 'bottom'
            })
          }
          onHandleModalCloseClick()
        }
        catch (error) {
          showHTTPErrorNotification({ status: error.status, message: 'Error creating collection' })
        }
        finally {
          isCreateCollectionLoading.value = false
        }
      }

      const getCollectionsToBeEdited = async () => {
        if (isEdit.value) {
          const result = await getScheduleCollections(
            { scheduleId: routeParams.value.id, collectionId: routeParams.value.collectionId }
          )
          const { type: options, subtype: choices } = getElementTypes(SEARCH_RESULT_TYPES.OPTIONS)
          const { type: components, subtype: componentVariants } = getElementTypes(SEARCH_RESULT_TYPES.COMPONENTS)

          collectionName.value = result.collectionName

          result.options.forEach(option => {
            useElementsProvider.onHandleParentElementClick(
              { item: options, subItem: choices },
              { item: option, isChosen: false, fetchedElements: result }
            )
          })
          result.components.forEach(component => {
            useElementsProvider.onHandleParentElementClick(
              { item: components, subItem: componentVariants },
              { item: component, isChosen: false, fetchedElements: result }
            )
          })
          const isPreviouslySelectedForLE = result.components.some(item => item.selectedForLE)
          useElementsProvider.setSelectedForLE(isPreviouslySelectedForLE)
        }
      }

      watch(
        () => [
          useElementsProvider.selectedElements.options,
          useElementsProvider.selectedElements.components
        ],
        ([newOptions, newComponents]) => {
          allowSubmit.value = newOptions.length > 0 || newComponents.length > 0
        },
        { deep: true }
      )

      onMounted(async () => {
        await getCollectionsToBeEdited()
      })

      return {
        showModal,
        onHandleModalCloseClick,
        onHandleSubmit,
        isCreateCollectionLoading,
        isEdit,
        getCollectionsToBeEdited,
        allowSubmit
      }
    }
  }
</script>

<style lang="scss">
  .schedules-search {
    height: 100%;

    .q-dialog__inner {
      padding: 0;
    }

    .q-card__section {
      padding: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
    }

    &__form {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
  }
</style>
